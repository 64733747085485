import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authentication";
import configs from '../../inc/helpers.js'
import Global from '../../inc/Global'
import Axios from 'axios';
import { getTokenDevice } from '../../inc/FireBase';
import ReactTooltip from 'react-tooltip';

import Popup from 'reactjs-popup';

var self
class ProfileMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            profil: '',
            name: '',
            lastname: '',
            links: [],
            copySuccess: false
        }
        self = this
    }

    async onLogout(e) {
        e.preventDefault(); 

        //LogOut 
        let getToken = await getTokenDevice()
        if( getToken.success ){ 

            let data = new FormData();
            data.append('token',getToken.token)
            data.append('device',2)

            Axios
            .post(Global.API_URL + "/api/deconnect", data)
            .then((res) => {
                this.props.logoutUser(this.props.history);
            })
            .catch((err) => {this.props.logoutUser(this.props.history);})
        }else{
            this.props.logoutUser(this.props.history);
        }
    }
    async onLogoutStatic() {

        //LogOut 
        let getToken = await getTokenDevice()
        if( getToken.success ){ 

            let data = new FormData();
            data.append('token',getToken.token)
            data.append('device',2)

            Axios
            .post(Global.API_URL + "/api/deconnect", data)
            .then((res) => {
                this.props.logoutUser(this.props.history);
            })
            .catch((err) => {this.props.logoutUser(this.props.history);})
        }else{
            this.props.logoutUser(this.props.history);
        }
    }

    componentDidMount() {
        this.setState({
            profil: this.props.auth.user.profil === '0' ? false : this.props.auth.user.profil,
            name: this.props.auth.user.name,
            lastname: this.props.auth.user.lastname, 
        })
        this.getlinks();
    }

    getlinks() {
        Axios
        .get(Global.API_URL + "/api/getlink", {})
        .then((res) => {
            if (res.data.status) {
                this.setState({
                    links: res.data.lien
                })
            }
        }).catch((err) => {
            this.onLogoutStatic()
        })
    }
    copyToClipBoard = async copyMe => {
        try {
            await navigator.clipboard.writeText(copyMe);
            this.setState({
                copySuccess: true
            })
            setTimeout(() => {
                this.setState({
                    copySuccess: false
                })
            }, 2000);
        } 
        catch (err) {
            this.setState({
                copySuccess: false
            })
        }
     };

    static refrechLinks(){
        self.getlinks()
    }

    render() {

        return (
            <div className="profil"> 
                <div className="figure vs-desktop" data-tip data-for="toggleMoi" >
                    <label
                        id="header-profile-image"
                        className="img"
                        htmlFor="list-item-profil"
                        style={this.state.profil ?
                            { backgroundImage: 'url(' + Global.API_URL + this.state.profil + ')' }
                            :
                            { backgroundColor: 'rgb(214 214 214)' }
                        }>
                        {(!this.state.profil && this.state.name && this.state.lastname) &&
                            this.state.name.charAt(0) + this.state.lastname.charAt(0)
                        }
                        <img src={configs.GetImage('profil.png')} alt="" />
                    </label>
                    <label className="p-username" id="header-profile-name" htmlFor="list-item-profil">
                        {this.state.name ? this.state.name.toLowerCase() : ''}
                    </label>
                </div>
                <ReactTooltip id="toggleMoi" place="bottom" type="dark" effect="solid">
                    Moi
                </ReactTooltip>
                <div className="vs-mobile">
                    <label className="profile-mob" htmlFor="list-item-profil">
                        <i className="fa fa-bars"></i><br />
                    </label>
                </div>
                <input type="checkbox" className="header-submenu-radio" id="list-item-profil" />
                <div className="profil-list">
                    <ul>
                        <li><Link to="/profil"><ion-icon name="person-circle-outline"></ion-icon> Profil</Link></li>
                        <li><Link to="/mediatheque"><ion-icon name="documents-outline"></ion-icon> Mes documents</Link></li>
                        <li><Link to="/mes-enregistrements"><ion-icon name="bookmark-outline"></ion-icon> Mes enregistrements</Link></li>
                        <li><Link to="/mes-publications"><ion-icon name="book-outline"></ion-icon> Mes publications</Link></li>
                        <li>
                            <Popup
                                trigger={
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault()
                                        }}>
                                        <ion-icon name="phone-portrait-outline"></ion-icon>
                                        <span>
                                            Application mobile
                                        </span>
                                    </a>
                                }
                                modal
                                className="modal-bq-code-cnt"
                            >
                                {close => (
                                    <div className="modal-bq-code">
                                        <div className="close" onClick={close}>
                                            <ion-icon name="close-outline"></ion-icon>
                                        </div>
                                        {/* <div className="header"> Modal Title </div> */}
                                        <div className="content">
                                            <div className='text'>
                                                <h2>
                                                    Scanner le QR code pour télécharger
                                                    l'application mobile
                                                </h2>
                                                <p>
                                                    Dirigez l'appareil photo de votre smartphone
                                                    pour scanner le QR code et cliquez sur le lien
                                                    généré.
                                                </p>
                                            </div>
                                            <img src={`${Global.API_URL}/cgu/qr-code.png`} />

                                            <div className='link'>
                                                <p>
                                                    {Global.APPMOBILE_URL}
                                                </p>
                                                <p 
                                                    onClick={(e) => this.copyToClipBoard(Global.APPMOBILE_URL)} 
                                                >
                                                    {
                                                        this.state.copySuccess ?
                                                            <>
                                                                Copié
                                                            </>
                                                        :
                                                            <>
                                                                Copier le lien
                                                            </>

                                                    }
                                                    
                                                </p>
                                            </div>
                                        </div>
                                        
                                    </div>
                                )}
                            </Popup>
                        </li>
                        <li className="seperate"></li>
                        {
                            this.state.links.length > 0 &&
                            <>
                                <li>
                                    <strong style={{ color: '#757575' }}>Mes liens</strong>
                                    <ul className="my-links">
                                        {this.state.links.map((link, key) => {
                                            return (
                                                <li key={key}>
                                                    <a href={link.link} target="_blank">
                                                        <span className="icon">
                                                            <img src={link.linkicon ? Global.API_URL + link.linkicon : configs.GetImage('link-icon.png')} />
                                                        </span>
                                                        <span>
                                                            {link.linktitle}
                                                        </span>
                                                    </a>
                                                </li>
                                            )
                                        })}
                                        
                                    </ul>
                                </li>
                                <li className="seperate"></li>
                            </>
                        } 
                        <li><Link to="/notification"><ion-icon name="cog-outline" style={{fontSize: "21px"}}></ion-icon>Notifications</Link></li>
                        {
                            !Global.IS_PROD && configs.isAdmin() &&
                            <>
                                <li><Link to="/administration"><ion-icon name="settings-outline"></ion-icon>Administration</Link></li> 
                                <li className="seperate"></li>
                            </>
                        }

                        <li><Link to="" onClick={this.onLogout.bind(this)}><ion-icon name="log-out-outline"></ion-icon> Déconnexion</Link></li>
                    </ul>
                </div>
            </div>
        )
    }
}

ProfileMenu.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(withRouter(ProfileMenu));